import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  Imprint: "en/imprint"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/impressum"
  },
  {
    hreflang: "en",
    href: "/en/imprint"
  },
  {
    hreflang: "x-default",
    href: "/en/imprint"
  }
];

const Imprint = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Imprint"
        description="Information about the operator of the website, as well as legal notices."
        lang="en"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article={false}>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Imprint</H>
        <p>Operator of the website bluerivermountains.com:</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 Copenhagen
          <br />
          Denmark
        </p>
        <p className="baseline">
          Email: <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Telefone: +45 93 86 85 64
        </p>
        <p className="baseline">Tax identification number: 39474263</p>
        <p>This site is copyright of Matthias Kupperschmidt.</p>
        <H as="h2">Legal</H>
        <p>
          I regularly review the information on this website. Despite all care, the data may have changed in the
          meantime. A liability or guarantee for the topicality, correctness and completeness of the information can
          therefore not be accepted. The same applies to websites that are referred to via hyperlinks. Matthias
          Kupperschmidt is not responsible for the content of the websites that are reached as a result of such a
          connection. Reproduction of information or data – especially the use of texts, parts of texts or images –
          requires the prior consent. This also applies to all blog entries. I point out that I store all data entered
          into the search mask and do not use this data for own advertisement, nor pass it on to third parties.
        </p>
        <p>
          Further information can be found on my <Link to="/en/privacy-policy">privacy policy</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Imprint;
